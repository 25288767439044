import Opx from "../../assets/svg/opx.svg"

export default function Card() {
  return (
    <>
      <div className="hidden lg:grid grid-cols-4 w-[90%] md:w-[97%] 2xl:w-[80%] mx-auto">
      <div className="border-b-[1px] border-r-[1px] border-[#54FF7A] py-10">
        <img src={Opx} alt="" className="mx-auto" />
      </div>
      <div className="border-b-[1px] border-r-[1px] border-[#54FF7A] py-10">
        <img src={Opx} alt="" className="mx-auto" />
      </div>
      <div className="border-b-[1px] border-r-[1px] border-[#54FF7A] py-10">
        <img src={Opx} alt="" className="mx-auto" />
      </div>
      <div className="border-b-[1px]  border-[#54FF7A] py-10">
        <img src={Opx} alt="" className="mx-auto" />
      </div>
      <div className="border-b-[1px] border-r-[1px] border-[#54FF7A] py-10">
        <img src={Opx} alt="" className="mx-auto" />
      </div>
      <div className="border-b-[1px] border-r-[1px] border-[#54FF7A] py-10">
        <img src={Opx} alt="" className="mx-auto" />
      </div>
      <div className="border-b-[1px] border-r-[1px] border-[#54FF7A] py-10">
        <img src={Opx} alt="" className="mx-auto" />
      </div>
      <div className="border-b-[1px]  border-[#54FF7A] py-10">
        <img src={Opx} alt="" className="mx-auto" />
      </div>
      <div className="border-b-[1px] border-r-[1px] border-[#54FF7A] py-10">
        <img src={Opx} alt="" className="mx-auto" />
      </div>
      <div className="border-b-[1px] border-r-[1px] border-[#54FF7A] py-10">
        <img src={Opx} alt="" className="mx-auto" />
      </div>
      <div className="border-b-[1px] border-r-[1px] border-[#54FF7A] py-10">
        <img src={Opx} alt="" className="mx-auto" />
      </div>
      <div className="border-b-[1px] border-[#54FF7A] py-10">
        <img src={Opx} alt="" className="mx-auto" />
      </div>
      </div>
    </>
  )
}
